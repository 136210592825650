@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Global Classes */

:root{
    --header-background: black;
    --header-height: 4em;
    --burnt-orange: #702F00;
    --burnt-orange-light: #c55a0d;
    --offWhiteHeaders: #c2c2c2;
    --offWhiteBody: #e1e1e1;

    --bodyGray: #333;
    --coolColorOne: #446a88;
    --coolColorTwo: #32788f;
    --coolColorThree: #1d808d;
    --coolColorFour: #198886;
    --coolColorFive: #2b8673;
    --coolColorSix: #448864;

    --warmColorOne: #3e0101;
    --warmColorTwo: #660d03;
    --warmColorThree: #663303;
    --warmColorFour: #664803;
    --warmColorFive: #676202;

    --winningColorOne: #5eae54c7;
    --losingColorOne: #930303dc;


}

*, *::before, *::after {
    box-sizing: border-box;
}

body { 
    margin: 0;
    background: #222;
}

.content { 
    position: absolute;
    top: var(--header-height);
    height: 100%;
    width: 100%;
    display: grid;
    color: var(--offWhiteBody);
    overflow-x: hidden;
}

.pl_1 { 
    padding-left: 10px;
}

.pl_2 { 
    padding-left: 2em;
}

.pr_1 { 
    padding-right: 10px;
}

.ml_1 {
    margin-left: 10px;
}

.mr_1 {
    margin-right: 10px;
}

.p2{
    padding: 20px;
}

.m1 {
    margin: 10px
}

.mr_4 {
    margin-right: 40px;
}

.ml_4 {
    margin-left: 40px;
}

.mt_1 {
    margin-top: 10px;
}

.mt_2 {
    margin-top: 20px;
}

.mt_3 {
    margin-top: 30px;
}

.mt_4 {
    margin-top: 40px;
}

.mt_5 {
    margin-top: 50px;
}

.heavy_font {
    font-weight: 900;
}

.underline_font {
    text-decoration: underline;
}


.flexHorizontal {
    display: flex;
    align-items: center;
}

.spaceBetween {
    justify-content: space-between;
}

.subText {
    font-weight: 100;
    color: #ccc;
}

/* Loading Spinner */

@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: -0.25em;
    left: 12em;
    width: 50px;
    height: 50px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite;
}   

/* Mobile Nav Styling  */
header {
    background: var(--header-background);
    border-bottom: 2px solid var(--burnt-orange);   
    color: var(--offWhiteHeaders);
    text-align: center;
    position: absolute;
    width: 100%;
    height: var(--header-height);
    z-index: 999;
}

h3 {
    padding-top: 0.35em;
    font-size: 1em;
}

.nav-toggle {
    display: none;
}

.nav-toggle-label {
    position: absolute;
    top: 3px;
    left: 0;
    margin-left: 1em;
    height: 100%;
    display: flex;
    align-items: center;

}

.nav-dropdown-icon {
    height: 20px;
    width: 20px;
    color: white;
    cursor: pointer;
}

nav {
    position: absolute;
    text-align: left;
    top: 100%;
    left: 0;
    background: var(--header-background);
    width: 100%;
    transform: scale(1,0);
    transform-origin: top;
    transition: transform 150ms ease-in-out;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: x-small;
    text-align: left;
    width: fit-content;
    white-space: pre;
}

nav li {
    border-top: 1px solid #333;
    padding: 0.75em 0.75em;
}

.navIcon {
    padding: 0 0.5em;
}

.navBarDropDownIcon > svg{
    display: none;
}

.navBarDropDownIcon {
    padding-left: 0.2em;
}

.navParentLink {
    padding: 0.5em 0em;
}

.navSubMenu {
    padding: 0.5em 1em;
}

.navSubMenuItem {
    padding: 0.75em 0;
}

.navSubMenuItem > a > span:first-child{
    display: none;
}

nav a {
    color: var(--offWhiteHeaders);
    text-decoration: none;
    font-size: 1rem;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 50ms ease-in-out;
}

nav a:hover {
    color: var(--burnt-orange-light);
}

.nav-toggle:checked ~ nav {
    transform: scale(1,1);
}

.nav-toggle:checked ~ nav a {
    opacity: 1;
    transition: opacity 100ms ease-in-out 100ms;
}

.navParentLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobileNavDropDownIcon {
    display: flex;
    font-size: 2em;
}

/* Desktop Nav Styling */
@media screen and (min-width: 1300px){
    .nav-toggle-label {
        display: none;
    }

    header {
        position: fixed;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr
    }

    .title { 
        grid-column: 1;
        padding-top: 1em;
    }

    nav {
        all: unset;
        grid-column: 2;
        display: grid;
        padding-top: 1em;
    }

    nav a {
        opacity: 1;
        position: relative;
    }

    .navLink::before {
        content: '';
        display: block;
        height: 5px;
        background: var(--burnt-orange);
        margin-bottom: 3px;
        transform: scale(0,1);
        transition: transform ease-in-out 100ms;
    }

    .navLink:hover::before {
        transform: scale(1,1);
    }

    nav ul {
        width: auto;
        display: flex;
    }

    .navTopPadding {
        padding-top: 2rem;
    }

    nav li {
        /* grid-column: span 1; */
        border-top: none;
        padding: 1em 1.2em
    }

    .navIcon {
        padding: 0 0.5em;
    }

    .navBarDropDownIcon > svg{
        display: inline;
        position: fixed;
        width: 20px;
        height: 20px;
    }

    .navSubMenu {
        grid-column: span 1;
        grid-row: 2;
        background-color: black;
        font-size: xx-small;
        border: 1px solid var(--burnt-orange);
        margin: 1em 0;
        padding: 1em;
        border-radius: 0.5rem;
        box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
          0 4px 6px -2px rgba(71, 63, 79, 0.16);
        display: none;
    }

    .navSubMenu .navIcon { 
        padding-right: 0.5rem;
    }

    .showNavSubMenu {
        display: block;
    }

    .hideNavSubMenu {
        display: none;
    }

    .navParentLink {
        all: unset;
    }

    .navParentLink > a > span:first-child{
        display: inline-block;
    }

    .navSubMenu a {
        font-size: 1.2em;
    }

    .mobileNavDropDownIcon {
        display: none;
    }

    .mediumFont {
        font-size: medium !important;
    }
}

/* myTable and Home Page */
.table-container {
    width: 100%;
    overflow-x: scroll;
    font-weight: 100;
}

table.myTable {
    width: 100%;
    border-collapse: collapse;
    background: #323232;
}

.myTable caption,
.myTable th,
.myTable td {
    padding: 1rem;
}

.myTable caption, 
.myTable th {
    text-align: left;
}

.myTable caption {
    background: hsl(0 0% 0%);
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
}

.myTable th { 
    background: hsl(0 0% 0% / 0.5);
}

.myTable tr:nth-of-type(2n) {
    background: hsl(0 0% 0% / 0.1)
}

.myTable thead th{
    font-weight: 500;
    text-align: left;
    border-bottom: 2px solid #ccc;
}

.myTable tbody td {
    padding: 1rem;
}
  
.myTable tbody tr:hover {
    background-color: #333;
}

.myTable .team-display {
    margin-top: 10px;
}

@media (max-width: 650px){

    .myTable th {
        display: none;
    }
    .myTable td {
        display: grid;
        grid-template-columns: 15ch auto;
        padding: 0.5rem 1rem !important;
        font-weight: 100;
    }

    .myTable td:first-child {
        padding-top: 1rem !important;
    }

    .myTable td:last-child {
        padding-bottom: 1rem !important;
    }

    .myTable td:not(:nth-of-type(1)):before {
        content: attr(data-cell) ": ";
        font-weight: 400;
    }
    td:nth-of-type(1) {
        display: flex;
        align-content: center;
    }
}

.smallAvatar img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid black;
}

.pastChampions {
    width: 95%;
    margin: 20px;
}

table.pastChampions {
    width: 70%;
    border: solid 1px black;
    border-radius: 10px;
    margin: 20px
}

.pastChampions thead th{
    font-weight: 500;
    text-align: left;
    border-bottom: 2px solid black;
}


/* All Analytics Page Formatting */

    /* Grid Definitions */
@media (min-width: 1350px){

    .loadingContainer {
        position: fixed;
        top: 50%;
        left: 40%;
        display: flex;
    }

    .insightContainer {
        padding: 20px;
        margin: 20px;
        background-color: #333;
        border-radius: 25px;
        border: 1px solid var(--burnt-orange);
    }

    .sleeperInsights {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(10em);
        gap: 10px;
    }
    
    .averagePointsContainer {
        grid-column: 1;
        grid-row: 1 / 9;
    }

    .managerActivityContainer {
        grid-column: 2;
        grid-row: 1 / 9;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .standardDeviationContainer {
        grid-column: 1;
        grid-row: 9 / 14;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .keyMatchupsContainer {
        grid-column: 2;
        grid-row: 9 / 14;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        height: fit-content;
    }

    .luckRatingContainer {
        grid-column: 1;
        grid-row: 14 / 20;
    }

    .teamLuckContainer { 
        width: 50%;
    }

    .outlierWeeksContainer {
        grid-column: 2;
        grid-row: 14 / 20;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    
    .bestWeekContainer {
        grid-column: 1;
    }
    
    .worstWeekContainer {
        grid-column: 2;
    }
}

@media (min-width: 650px) and (max-width: 1350px){

    .loadingContainer {
        position: fixed;
        top: 50%;
        left: 40%;
        display: flex;
    }

    .insightContainer {
        padding: 20px;
        margin: 20px;
        background-color: #333;
        border: 1px solid var(--burnt-orange);
        border-radius: 25px;
    }
    
    .averagePointsContainer {
        margin-bottom: 25px;
    }

    .managerActivityContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 25px;
    }

    .standardDeviationContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 25px;
    }
    
    .keyMatchupsContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        height: fit-content;
        margin-bottom: 25px;
    }

    .luckRatingContainer {
        margin-bottom: 25px;
    }

    .teamLuckContainer { 
        width: 45%;
    }
    .outlierWeeksContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    
    .bestWeekContainer {
        grid-column: 1;
    }
    
    .worstWeekContainer {
        grid-column: 2;
    }

}

@media (max-width: 650px) {
    /* Loading Spinner and Container*/
    .loadingContainer {
        position: fixed;
        margin: 50px 0px 0px 25px;
    }
    
    .sleeperInsights {
        margin: 25px;
    }

    .averagePointsContainer{
        margin-bottom: 5em;
    }

    .managerActivityContainer{
        margin-bottom: 5em;
    }

    .standardDeviationContainer{
        margin-bottom: 5em;
    }

    .keyMatchupsContainer{
        margin-bottom: 5em;
    }

    .outlierWeeksContainer {
        margin-bottom: 5em;
    }
}


/* Analytics Shared Classes */
.mediumAvatar {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 2;
    background-color: black;
}

.largeAvatar {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 2;
    background-color: black;
}



.coloredBar { 
    display: flex;
    align-items: center;
    height: 1.9em;
    margin-left: -5px;
    border-radius: 0 .9em .9em 0;
    z-index: 1;
    min-width: 20%;
}



/* Average Points */
.teamName {
    position: relative;
    top: 10px;
    left: 70px;
}

.teamPoints {
    position: relative;
    left: 28px;
}

.teamBar:nth-child(1n) .coolBar { 
    background-color: var(--coolColorOne);
}

.teamBar:nth-child(2n) .coolBar { 
    background-color: var(--coolColorTwo);
}

.teamBar:nth-child(3n) .coolBar { 
    background-color: var(--coolColorThree);
}

.teamBar:nth-child(4n) .coolBar { 
    background-color: var(--coolColorFour);
}

.teamBar:nth-child(5n) .coolBar { 
    background-color: var(--coolColorFive);
}

.teamBar:nth-child(6n) .coolBar { 
    background-color: var(--coolColorSix);
}



/* Standard Deviation */
.teamBar:nth-child(1n) .warmBar { 
    background-color: var(--warmColorOne);
}

.teamBar:nth-child(2n) .warmBar { 
    background-color: var(--warmColorTwo);
}

.teamBar:nth-child(3n) .warmBar { 
    background-color: var(--warmColorThree);
}

.teamBar:nth-child(4n) .warmBar { 
    background-color: var(--warmColorFour);
}

.teamBar:nth-child(5n) .warmBar { 
    background-color: var(--warmColorFive);
}

/* Grid Definition*/
.matchupContainer {
    display: grid;
    grid-template-columns: 1fr 25px 25px 1fr;
    grid-template-rows: 20px auto;
}

.matchupWeek {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-bottom: -25px;
}

.matchupSpread {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    justify-self: center;
    margin-bottom: -25px;
}

.shiftDown {
    margin-top: 15px;
}

.team1Bar {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

.team2Bar {
    grid-column: 3 / 5;
    grid-row: 2 / 3;

}

.vsContainer {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    display: flex;
    align-items: flex-end;
    z-index: 2;
}

.vsBubble {
    background-color: black;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
.vsText {
    position: absolute;
    margin-left: 15px;
    margin-bottom: 15px;
}

.matchupContainer .matchupTeam1Name {
    display: flex;
    justify-content: flex-end;
}

.matchupContainer .matchupTeam2Name {
    display: flex;
    justify-content: flex-start;
}

.matchupContainer .teamName1Text {
    position: relative;
    top: 5px;
    margin-right: 40px;
}

.matchupContainer .teamName2Text {
    position: relative;
    top: 5px;
    margin-left: 40px;
}

.matchupContainer .player2Name {
    position: relative;
    top: 10px;
    left: 0px;
}

.matchupBar {
    display: flex;
    align-items: center;
    height: 1.9em;
    z-index: 1;
    width: 100%;
}

.winningBar {
    background-color: var(--winningColorOne);
}

.losingBar {
    background-color: var(--losingColorOne);
}

.player1Bar {
    border-radius: 0 .9em .9em 0;
    margin-left: -5px;
    display: flex;
    justify-content: flex-end;
}

.player2Bar {
    border-radius: 0.9em 0 0 0.9em;
    margin-right: -5px;
    display: flex;
    justify-content: flex-start;
}

/* Manager Activity Styling */
.pickupsContainer {
    grid-column: 1;

}
.tradesContainer {
    grid-column: 2;
}

/* Luck Rating Styling */
.teamLuckContainer {
    display: grid;
    grid-template-columns: 1fr 25px 25px 1fr;
    grid-template-rows: 20px auto;
}

.luckAvatar {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    display: flex;
    align-items: flex-end;
    z-index: 2;
}

.negativeLuckContainer {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: row-reverse;
}

.negativeLuckBar { 
    display: flex;
    flex-direction: row-reverse;
    align-self: center;
    align-items: center;
    height: 1.9em;
    border-radius: .9em 0 0 .9em;
    z-index: 1;
    min-width: 45%;
    background-color: var(--losingColorOne);
}

.negativeLuckTeamPoints {
    margin-right: 35px;
}

.negativeLuckTeamName {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: flex-end;
    margin: 10px 5px;
}

.positiveLuckTeamName {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    margin: 10px 5px;
}

.positiveLuckContainer {
    grid-column: 3 / 5;
    grid-row: 2 / 3;
    display: flex;
}

.positiveLuckBar { 
    display: flex;
    align-self: center;
    align-items: center;
    height: 1.9em;
    border-radius: 0 .9em .9em 0;
    z-index: 1;
    min-width: 35%;
    background-color: var(--winningColorOne);
}

.positiveLuckTeamPoints {
    margin-left: 35px;
}

/* History Page */
.historyPageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px,450px));
    gap: 1em;
}

.historyPageGrid a {
    height: 25em;
    padding: 20px;
    margin: 20px;
    background-color: #333;
    border-radius: 5%;
    color: var(--offWhiteHeaders);
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center ;
}

.historyPageGrid a:hover {
    color: var(--burnt-orange-light);
    box-shadow: inset 0 0 0.5em var(--burnt-orange);
}

.bigIcon {
    font-size: 6em;
}


/* Trophy Room  */
.trophyRoom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trophyRoomSeasonGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.25fr 1fr 1fr;
    place-items: center;
}

.trophyRoomSeason {
    grid-column: 2 / 3;
    grid-row: 1;
    align-self: self-end;
    margin: 0;
}

.trophyRoomChampion {
    grid-column: 2 / 3;
    grid-row: 2;
}

.trophyRoomRunnerUp {
    grid-column: 1 / 2;
    grid-row: 3;
}

.trophyRoomHighScorer {
    grid-column: 3 / 4;
    grid-row: 3;
}

.trophyRoomLoser {
    grid-column: 2 / 3;
    grid-row: 3;
}

.trophyRoomUserCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Top Managers  */

@media screen and (min-width: 1300px){
    .teamNameGrid {
        display: grid;
        grid-template-columns: 0.2fr 1fr;
    }
    
    .splitInsight {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}

@media screen and (max-width: 1300px){
    .teamNameGrid {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
    }
}

.recordContainer {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    display: flex;
    align-items: flex-end;
    z-index: 2;
    margin-left: -1em;
}

.recordBubble {
    background-color: black;
    border-radius: 50%;
    height: 50px;
    width: 55px;
}
.recordText {
    position: absolute;
    margin-left: 5px;
    margin-bottom: 15px;
}

.noTextBreak {
    white-space: pre;
}

/* Seasons Log  */
.seasonsLogContainer {
    overflow-x: hidden;
}
/* Nav Bar Managers  */

.navBarManagersList {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    overflow-x: scroll;
    white-space: pre;
    justify-content: space-between;
    position: sticky;
    top: -1px;
    z-index: 4;
    background-color: var(--bodyGray);
    box-shadow: 0px 2px 5px 0px black;
}

.navBarManagersList > li {
    flex-grow: 1;
    flex-basis: 0;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-bottom: 2px solid black;
    transition: border-bottom ease-in-out 150ms, opacity ease-in-out 200ms

}

.navBarManagersList > li:hover {
    border-bottom: 2px solid var(--burnt-orange);
    opacity: 1;
}

.navBarManagersList > li:not(:hover) {
    opacity: 0.9;
}

.highlightElement {
    background-color: black;
    opacity: 1 !important;
    border-bottom: 2px solid var(--burnt-orange) !important;
}


@media (min-width: 1350px){
    .seasonsLogHeader {
        display: flex;
        justify-content: center;
    }
}

.managerSummary {
    padding: 20px;
    margin: 20px;
}

.managerSummaryHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.twoColumnContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.tinyGap {
    gap: 0.5em;
}

.smallGap {
    gap: 1em;
}


.seasonIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 2;
    background-color: black;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.managerPlaceholder {
    width: 40%;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
    padding: 25px;
    white-space: pre;
}

/* Head to Head Page */

.headToHeadPage {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.headToHeadSelectContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.headToHeadPage select {
    display: inline-block;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid var(--burnt-orange);
    border-radius: 5px;
    background-color: black;
    color: white;
    display: inline-grid;
}


.headToHeadPage select:focus-visible {
    outline: none
}

.comparisonContainer {
    padding: 2em 2em;
}

.comparisonContainer div{
    font-size: 18px;
}

.comparisonHeader {
    display: flex;
    justify-content: space-between;
    gap: 2em;
}

.comparisonSection {
    display: grid;
    grid-template-columns: 0.5fr 10px 1fr 10px 0.5fr;
    align-content: center;
    padding-bottom: 10px;
}

.justifyEnd {
    justify-self: end;
}

.justifyCenter {
    justify-self: center;
}

.textCenter {
    text-align: center;
}

.displayNone {
    display: none;
}

.triangleLeft {
    position: absolute;
    place-self: center;

    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid var(--offWhiteBody); /* Change color as needed */
}

.triangleRight {
    position: absolute;
    place-self: center;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid var(--offWhiteBody); /* Change color as needed */
}

.gameLogContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.gameLogHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0;
}

.gameLogBody {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: max-height 150ms ease-in-out, opacity 100ms ease-in-out, visibility 0s 300ms;
}

.openPlayoffGames {
    max-height: 5000px;
    opacity: 1;
    visibility: visible;
    transition: max-height 100ms ease-in-out, opacity 100ms ease-in-out, visibility 0s;
}

.openRegularSeasonGames {
    max-height: 5000px;
    opacity: 1;
    visibility: visible;
    transition: max-height 100ms ease-in-out, opacity 100ms ease-in-out, visibility 0s;
}

.fontSize2em {
    font-size: 2em;
}

.gameLogSubContainer {
    width: inherit;
    background-color: var(--bodyGray);
    border-radius: 20px;
    padding: 1em 1em;
    margin: 1em 1em;
    border: 1px solid var(--burnt-orange);
}

@media (min-width: 375px) and (max-width: 700px){
    .headToHeadSelectContainer{
        gap: 10px
    }

    .headToHeadPage select {
        padding: 10px 10px;
        font-size: 13px;
        cursor: pointer;
        border: 2px solid var(--burnt-orange);
        border-radius: 5px;
        background-color: black;
        color: white;        width: 100%;
    }


}


/* Styling for very small screens*/
@media (min-width: 300px) and (max-width: 374px){
    h1 {
        font-size: 1.5em;
    }
    h2 {
        font-size: 1em;
    }

    .managerSummary {
        padding: 0.25em;
        margin: 1em;
    }

    .headToHeadBody {
        width: 90%;
    }

    .headToHeadSelectContainer{
        gap: 5px
    }

    .comparisonContainer {
        padding: 0.5em 0.5em;
        width: 90%;
    }

    .comparisonContainer div{
        font-size: 14px;
    }

    .gameLogContainer {
        width: 95%;
    }

    .gameLogSubContainer {
        width: inherit;
        margin: 0.5em 0em;
    }
    
    .gameLogBody {
        width: 95%;
    }
}

/* Standings */

.standingsPage {
    display: grid;
}

.standingsPageHeader {
    background: hsl(0 0% 0%);
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
}

.standingsPage a {
    height: 5em;
    margin: 1em;
    background-color: #333;
    border-radius: 5%;
    color: var(--offWhiteHeaders);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.standingsPage h2 {
    text-align: center;
}

.standingsPage a:hover {
    color: var(--burnt-orange-light);
    box-shadow: inset 0 0 0.5em var(--burnt-orange);
}

.bigIcon {
    font-size: 6em;
}

.centerText {
    text-align: center;
}

@media (min-width: 630px){
    .standingsPage {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10em,20em));
        gap: 1em;
    }
    
    .standingsPage a {
        height: 20em;
    }
}

.homeIntro {
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 1em;
}

/* Preseason Info */
.preseasonHeader {
    background-color: black;
    color: var(--offWhiteHeaders);
    border-top: 2px solid var(--burnt-orange);
    border-bottom: 2px solid var(--burnt-orange);
    padding: 1em 0;
    margin: 0;
    text-align: center;
}

.preseasonBody {
    display: grid;
    justify-content: center;
    padding: 1em;
    gap: 1em;
}

.preseasonItem {
    width: 100%;
    background-color: var(--bodyGray);
    padding: 1em;
    border-radius: 10px;
    border: 1px solid var(--burnt-orange);
}

/* Countdown Timer */
.countdownTimerContainer {
    display: grid;
}

.countdownTimerHeader {
    text-align: center;
}
.timerBody {
    display: grid;
    text-align: center;
    align-items: center;
    gap: 0.5em;
}

.timerValues {
    grid-row: 1;
    font-size: 1.5em;
    display: grid;
    grid-template-columns: 1fr 10px;
}

.timerLabels {
    grid-row: 2;
    text-transform: capitalize;
}

.seasonResults {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seasonResultsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
}

.seasonResultsHeader {
    grid-column: 1 / 4;
    grid-row: 1;
    margin: 0;
}

@media (min-width: 1300px) {
    .homePageContainer {
        display: grid;
        grid-template-columns: 3fr 1fr;
    }

    .homeIntro{
        width: 50%;
    }

    .homeBody {
        background-color: black;
        display: flex;
        flex-direction: column;
    }

    .preseasonBody {
        flex: 1;
        padding: 2em;
        gap: 2em;
    }

    .preseasonHeader {
        border-top: none;
    }
}

.bylawContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.bylawSection {
    background-color: var(--bodyGray);
    padding: 1em;
    margin: 1em;
    border-radius: 10px;
    border: 1px solid var(--burnt-orange);
    overflow: hidden; 
}

.bylawHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.bigArrow {
    font-size: xx-large;
    transition: transform 0.3s ease;
}

.bylawBody {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease; 
}

.openBylaws {
    max-height: 1000px; 
}

@media (min-width: 1300px) {
    .bylawContainer {
        align-items: center;
    }
    .bylawSection {
        width: 50%;
    }
}